import Vue from 'vue';
import App from './app';



////// Web Site Types (SKW / ACS) //////
import websiteType from 'common/website-type';

Vue.use(websiteType);



////// Styles //////
// Keep this in order to make printing styles working
import './css/main.scss'
// SCSS from common
import 'common/css/skw-light-bs.scss'
import 'common/css/skw-dark-bs.scss'
import 'common/css/acs-light-bs.scss'
import 'common/css/acs-dark-bs.scss'
import 'common/css/common.scss'
import 'common/css/common-light.scss'
import 'common/css/common-dark.scss'
import 'common/css/skw-common.scss'
import 'common/css/skw-light.scss'
import 'common/css/skw-dark.scss'
import 'common/css/acs-common.scss'
import 'common/css/acs-light.scss'
import 'common/css/acs-dark.scss'
import 'common/common.css'
// PMS specific styles
import './css/site.css'



////// Debug //////
import debug from 'common/debug';

Vue.use(debug);



////// Axios API URL switches //////
import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.use(VueAxios, axios);



////// Notifications //////
import Notifications from 'vue-notification'

Vue.use(Notifications);



////// Bootstrap //////
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);



////// Moment //////
import VueMoment from 'vue-moment'
import moment from 'moment';

Vue.use(VueMoment);



////// Global template formatter registration //////
Vue.filter('formatDateTime', function (value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm')
    }
});

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
});

Vue.filter('formatTime', function (value) {
    if (value) {
        return moment(String(value.replace("Z", ""))).format('H:mm A')
    }
});



////// Loading spinners //////
import Loading from 'common/components/loading'
import LoadingSmall from 'common/components/loading-small'

// The single-word component name break rule of vue
// This name is used throughout the common Vue project, so it has to be kept
// The error is suppress using the following comment
// eslint-disable-next-line
Vue.component('loading', Loading);
Vue.component('loading-small', LoadingSmall);



////// Icons //////
import { FontAwesomeIcon } from './icons'

Vue.component('font-awesome-icon', FontAwesomeIcon)


////// Validators //////
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, numeric, is, min_value, double, max } from 'vee-validate/dist/rules';
import FormErrors from 'common/components/common/form-errors'
import ValidationErrors from 'common/components/common/validation-errors'

Vue.component('validation-observer', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);
Vue.component('form-errors', FormErrors);
Vue.component('validation-errors', ValidationErrors);

extend('required', {
    ...required,
    message: '{_field_} is required',
});

extend('required', required);
extend('max', max);
extend('numeric', numeric);
extend('double', double);
extend('is', is);
extend('min_value', min_value);

import * as validators from 'common/validation';
var validatorsObject = {};
Object.entries(validators).forEach(([name, exported]) => validatorsObject[name] = exported);

extend('udn-in-stockroom', validatorsObject['udnInStockroomValidator']);
extend('unique-door-description-apex', validatorsObject['uniqueDoorDescriptionApex']);
extend('unique-door-number-apex', validatorsObject['uniqueDoorNumberApex']);
extend('unique-username', validatorsObject['uniqueUsernameValidator']);
extend('udn', validatorsObject['udnValidator']);
extend('starts_with', validatorsObject['startsWithValidator']);
extend('udn-device-type', validatorsObject['udnDeviceTypeValidator']);
extend('unique-cloud-token-prefix', validatorsObject['uniqueCloudTokenPrefixValidator']);
extend('unique-domain', validatorsObject['uniqueDomainValidator']);
extend('unique-area-name', validatorsObject['uniqueAreaNameValidator']);
extend('unique-group-name', validatorsObject['uniqueGroupNameValidator']);
extend('unique-time-profile-name', validatorsObject['uniqueTimeProfileNameValidator']);
extend('unique-notice-message-name', validatorsObject['uniqueNoticeMessageNameValidator']);
extend('unique-notice-board-name', validatorsObject['uniqueNoticeBoardNameValidator']);
extend('keycode', validatorsObject['keycodeValidator']);
extend('not_in_twice', validatorsObject['notInTwiceValidator']);
extend('unique_fob_keycode', validatorsObject['uniqueFobKeycodeValidator']);
extend('unique_fob_reference', validatorsObject['uniqueFobReferenceValidator']);
extend('unique-person-name', validatorsObject['uniquePersonNameValidator']);
extend('unique-door-unlock-invitation-name', validatorsObject['uniqueDoorUnlockInvitationNameValidator']);
extend('unique-sim-reference', validatorsObject['uniqueSimReferenceValidator']);
extend('unique-input-name', validatorsObject['uniqueInputNameValidator']);
extend('unique-output-name', validators['uniqueOutputNameValidator']);
extend('truthy', validators['truthyValidator']);
extend('falsy', validators['falsyValidator']);
extend('prop-is', validators['propIsValidator']);



////// Router and store //////
import router from './router'
import store from './store'
import { sync } from 'vuex-router-sync'

Vue.router = router;
sync(store, router)



////// Authorisation //////
import VueAuth from '@websanova/vue-auth'

Vue.use(VueAuth, {
    auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
    http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
    router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
    loginData: { url: 'authentication/request-token?ignoreWorkingClient=true', method: 'POST', redirect: '/', fetchUser: true },
    fetchData: { url: 'authentication/user?ignoreWorkingClient=true', method: 'GET', enabled: true },
    refreshData: { url: 'authentication/refresh-token?ignoreWorkingClient=true', method: 'GET', enabled: true, interval: 30 }
});



////// Signal R //////
import SingalR from 'common/signalr';

Vue.use(SingalR);



////// i18n internationalisation //////
import VueI18n from 'vue-i18n'
import { loadLocaleMessages } from 'common/i18n'

Vue.use(VueI18n)
var i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages()
});



////// Vue app //////
Vue.config.productionTip = false

var app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')

export {
    app,
    router,
    store
}